<script setup lang="ts">
    import { LeadState } from '@containex/portal-backend-dto';
    import { computed } from 'vue';
    import Chip from 'primevue/chip';
    import { useI18n } from 'vue-i18n';

    const props = defineProps<{
        state: LeadState;
    }>();

    const { t } = useI18n();

    const leadData = computed(() => {
        switch (props.state) {
            case LeadState.New:
                return {
                    label: t('ACCOUNT.LEADS.LEAD_STATE.NEW'),
                    class: 'chip new-chip',
                };
            case LeadState.InProgress:
                return {
                    label: t('ACCOUNT.LEADS.LEAD_STATE.IN_PROGRESS'),
                    class: 'chip in-progress-chip',
                };
            case LeadState.Done:
                return {
                    label: t('ACCOUNT.LEADS.LEAD_STATE.DONE'),
                    class: 'chip done-chip',
                };
        }
        return {};
    });
</script>

<template>
    <Chip :label="leadData.label" :class="leadData.class" />
</template>

<style scoped lang="scss">
    @use '../../styling/main';

    .chip {
        color: main.$vt-c-white;
        border-radius: main.$spacing-3;
    }

    .new-chip {
        background-color: main.$color-orange;
    }

    .in-progress-chip {
        background-color: main.$color-chip-background-gray;
    }

    .done-chip {
        background-color: main.$color-order-successful;
    }
</style>
